import React from 'react'
import background from '../../../images/new/loginpage_background.svg'
import arrow from '../../../images/new/orange_arrow.svg'
import back from '../../../images/new/truck wash graphic.svg'
const LoginImg = () => {
  return (
    <div className='wash_auth_left'>
    <div className='wash_auth_img'>
        <img src={background} alt="background"/>
    </div>
    <div className='wash_auth_text'>
        <div className='wash_auth_text1'>more<span>Revenue</span></div>
        <div className='wash_auth_text2'>less Headache with <img src={arrow} alt="arrow"/></div>
        <div className='wash_auth_text3'>washtrax</div>
        <div className='wash_auth_text4'><img src={back} alt="arrow"/></div>
    </div>
   </div>
  )
}

export default LoginImg